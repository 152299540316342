
import { Component, Vue } from 'vue-property-decorator'

import UniversalCookie from 'universal-cookie'
import MoreBtn from '~/components/shared/MoreBtn.vue'
import SecondaryTitle from '~/components/shared/SecondaryTitle.vue'
import TitleWithViewAll from '~/components/shared/TitleWithViewAll.vue'
import PickupTitle from '~/components/shared/PickupTitle.vue'
import MixinKarteTracking from '~/components/mixins/MixinKarte'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'
import CarouselContents from '~/components/shared/CarouselContents.vue'
import TopCarousel from '~/components/shared/TopCarousel.vue'
import LoadingSpinner from '~/components/shared/LoadingSpinner.vue'
import SearchInputSuggestions from '~/components/shared/SearchInputSuggestions.vue'
import RecommendedContents from '~/components/shared/RecommendedContents.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import MixinMeta from '~/components/mixins/MixinMeta'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import CookiePolicyDialog from '~/components/shared/CookiePolicyDialog.vue'
import authSetting from '~/config/authSetting'
import { Meta } from '~/types/meta'
import { Stories } from '~/types/stories'
import { errorHandler } from '~/lib/errorHandling'
const camelcaseKeys = require('camelcase-keys')

@Component({
  components: {
    MoreBtn,
    SecondaryTitle,
    TitleWithViewAll,
    PickupTitle,
    CarouselContents,
    RecommendedContents,
    TopCarousel,
    LoadingSpinner,
    SearchInputSuggestions,
    ArticleCard,
    MessageAfterLogin,
    CookiePolicyDialog
  },
  mixins: [MixinKarteTracking, MixinMeta, MixinMemberId],
  async asyncData(context) {
    const { $axios } = context
    let top
    const topUrl = process.env.API_URL + '/top-all'
    const config = {
      auth: authSetting
    }
    try {
      await $axios.$get(topUrl, config).then((res) => {
        const resData = camelcaseKeys(res, { deep: true })
        top = Object.assign({}, resData)
      })
    } catch (err) {
      errorHandler(err, context)
      return err
    }
    return {
      top
    }
  },
  async fetch() {
    await this.$store.dispatch('tags/getAllTags', this.$nuxt.context)
  }
})
export default class Top extends Vue {
  private memberId!: number
  private searchWord: string = ''

  private isOpenCookiePolicyDialog: boolean = false
  private randomSearchWords: string[] = []
  private showRecommend: boolean = true
  private searchRecommendWord = [
    'エンジニア',
    'MR',
    '採用担当',
    '品質保証',
    '若手',
    'インターン',
    '女性 管理職',
    '男性 育休',
    '女性 SE',
    '福利厚生',
    'モビリティ',
    'コールセンター',
    '土木',
    '自動車',
    '銀行',
    '工場',
    '対談',
    '座談会',
    'イベント'
  ]

  private top: Stories.TopAll | null = null

  private get headBase(): Meta.HeadBase {
    return {
      title: 'talentbook(タレントブック)',
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL + this.$route.path
        }
      ]
    }
  }

  private getImagePath(path: string) {
    return path ? require(`~/assets/img/svg/${path}`) : ''
  }

  private memberOnlyContents = {
    contents: [
      {
        icon: 'add-user.svg',
        pointImg: 'point01.svg',
        text: '企業・カテゴリをフォローして新着コンテンツを確認！'
      },
      {
        icon: 'good.svg',
        pointImg: 'point02.svg',
        text: 'あなたの興味や関心から、おすすめ企業やカテゴリをレコメンド'
      },
      {
        icon: 'heart.svg',
        pointImg: 'point03.svg',
        text: 'お気に入りやあとで読みたいコンテンツをマイ本棚に保存！'
      }
    ]
  }

  private handleRecommendation(recommendAi): void {
    // recommendAPIのレスポンスに応じて表示制御
    this.showRecommend = recommendAi.length > 0
  }

  private categoryMixTitle(tags): string {
    return tags.map((v: any) => v.name).join(' + ')
  }

  private categoryMixLink(tags): string {
    return '/contents?category=' + tags.map((v: any) => v.id).join(',')
  }

  // categorizationからtargetIdsの順にcategoryの配列を返す
  private get extractedCategoryList() {
    const categorization = this.$store.state.tags.categorization
    const targetIds = [
      300, 236, 280, 296, 321, 228, 334, 324, 279, 160, 237, 124, 16, 36
    ]

    return targetIds.reduce((acc: any[], id) => {
      const foundItems = categorization.flatMap((category) =>
        category.records
          .filter((record) => record.id === id)
          .map((record) => ({
            id: record.id,
            name: record.name,
            slug: record.slug,
            categoryId: record.categoryId
          }))
      )
      return acc.concat(foundItems)
    }, [])
  }

  private changeFollowing(company): void {
    this.$store
      .dispatch('companies/updateCompaniesFollowing', {
        memberId: this.memberId,
        id: company.id,
        follow: company.following
      })
      .then(() => {
        this.$store.dispatch('companies/updateTopCompanyFollowing', company.id)
      })
  }

  private get sortBannersByOrder() {
    return this.top.banners.sort((a, b) => a.order - b.order)
  }

  // 現在の時刻をmsで取得
  private getCurrentTime(): number {
    return new Date().getTime()
  }

  // this.searchRecommendWordからランダムな配列を生成
  private randomArray(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  private mounted() {
    // cookie policyダイアログの表示制御
    const cookie = new UniversalCookie()
    const isReadCookiePolicy = cookie.get('read_cookie_policy')
    this.isOpenCookiePolicyDialog = !isReadCookiePolicy
    // rondam category 表示
    // sessionが切れたら削除されるくらいの一時的な保持でいいためsessionStorageを採用
    const storageData = sessionStorage.getItem('randomSearchWords')
    if (storageData) {
      const parsedData = JSON.parse(storageData)
      const currentTime = this.getCurrentTime()
      // 10分（600,000ms）以内ならstorageの値を使用
      if (currentTime - parsedData.createdAt < 600000) {
        this.randomSearchWords = parsedData.words
        return
      }
    }
    // 10分以上経過しているか、sessionStorageに値がなければ新しいrandomDataを作成
    const randomWords = this.randomArray([...this.searchRecommendWord]).slice(
      0,
      3
    )
    // sessionStorageに新しいデータを保存
    sessionStorage.setItem(
      'randomSearchWords',
      JSON.stringify({ words: randomWords, createdAt: this.getCurrentTime() })
    )
    this.randomSearchWords = randomWords
  }
}
